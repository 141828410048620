<template>
    <header class="ColorSkin">
		<div class="Container">
			<div class="Box">
				<Logo/>
			</div>
		</div>
	</header>
    <section class="SigninSection">
        <div class="Container">
            <div class="Box" style="padding-left:0px !important;">
                <h1>Gestore Taglie-Prezzi</h1>
            </div>
            <div class="Box WelcomeBox" style="padding-left:0px !important;">
                <div class="Welcome">
                    <div class="WelcomeText">
                        <div class="WelcomeSelect">
                            <form>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <select class="CustomSelect" v-model="this.selectedSocieta" @change="this.getKits()" >
                                            <option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div Sclass="ProfileMemberTable">
                <table class="PMSubTable" width="100%" border="0" cellspacing="0" cellpadding="0" >
                    <tbody>
                        <tr>
                            <th scope="col">Id Articolo</th>
                            <th scope="col">Descrizone</th>
                            <th scope="col" >Taglie</th>
                            <th scope="col" style="width:100px !important ;">Prezzo B2B</th>
                            <th scope="col" style="width:100px !important ;">Prezzo B2C</th>
                        </tr>
                        <tr v-for="art in this.listaArticoli" :key="art.CodiceArticolo">
                            <td style="padding: 9px;">{{art.CodiceArticolo.trim()}}</td>
                            <td style="padding: 9px;">{{art.DescrizioneArticolo.trim()}}</td>
                            <td style="padding: 9px;">
                                {{art.DescrTaglie ?? "Articolo non provvisto di taglie"}}
                            </td>
                            
                            <td style="padding: 9px;">
                                {{FixPrezzo(art.Prezzo)}}
                            </td>
                            <td style="padding: 9px;">
                                {{FixPrezzo(art.GestPrezzoB2c)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Loader from "@/components/Loader.vue";
import ProductsService from "@/services/products.service.js";

export default {
    name: "GestoreImmagini",
    data(){
        return {
            listaSocieta:[],
            CodSocieta: null,
            Categoria: null,
            listaArticoli: [],
            selectedSocieta: null
        }
    },
    components:{
        Logo,
        Loader
    },
    mounted: function(){
        this.$store.commit('setLoader', true);
        this.getSocietaCalcistiche();
    },
    methods:{
        getSocietaCalcistiche(){
            this.listaSocieta = this.$store.getters.getSocieta;
            if(this.listaSocieta.length > 0 ){
                this.selectedSocieta = this.listaSocieta[0].CodiceAzienda;
                if(this.selectedSocieta != null){
                    this.getKits();
                }
            }
        },
        getKits(){
            this.$store.commit('setLoader', true);
            ProductsService.getArticoliGestPrezziTaglie('b2b', 'KIT', this.selectedSocieta).then(data => {
                if (!data) {
                    this.$store.commit('setLoader', false);
                    this.$router.push("/");
                }
                else {
                    this.listaArticoli = data;
                    ProductsService.getArticoliGestPrezziTaglie('b2b', 'ACG', this.selectedSocieta).then(data => {
                        if (!data) {
                            this.$store.commit('setLoader', false);
                            this.$router.push("/");
                        }
                        else {
                            this.listaArticoli = this.listaArticoli.concat(data) ;
                            this.$store.commit('setLoader', false);
                        }
                    });
                }
            });
        },
        FixPrezzo(art){
            if(art != null && !isNaN(art)){
                return art.toFixed(2).replace('.',',');
            }else{
                return "0,00";
            }
            
        }
    }
}
</script>